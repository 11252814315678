<template>
  <div>
    <div class="jiejue-box"><span class="jj-text">产品详细介绍</span></div>
    <!-- <div class="fangan">产品详细介绍</div> -->
    <div class="">
      <img src="@/assets/images/new-smart-app.png" width="100%" height="auto">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.jiejue-box {
  height: 4.2rem;
  background: url(../../../../assets/images/tu1.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
}
.jiejue-box::after {
  content: '';
  display: block;
  width: 100%;
  height: 4.2rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.28);
}
.jj-text {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 4.2rem;
  position: relative;
  z-index: 10;
}
.fangan {
  font-size: 0.5rem;
  font-weight: 400;
  color: #333333;
  text-align: center;
  padding-top: 1.05rem;
  padding-bottom: 0.38rem;
}
.text {
  font-size: 0.28rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.5rem;
  padding: 0.34rem;
}
</style>
