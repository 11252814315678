<template>
  <div class="box">
    <div><slot name="img"></slot></div>
    <div>
      <slot name="tit"></slot>
    </div>
    <div>
      <slot name="head"></slot>
    </div>
    <div><slot name="text"></slot></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  padding-bottom: 0.5rem;
}
.imgs {
  width: 100%;
  display: block;
  margin: auto;
}
.imgs-3 {
  width: 100%;
  display: block;
  margin: auto;
}
.tit {
  font-size: 0.6rem;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0;
}
.num {
  padding: 0 0.4rem;
}
.tit::before {
  content: '';
  display: block;
  width: 1rem;
  height: 0.01rem;
  background: #333333;
}
.tit::after {
  content: '';
  display: block;
  width: 1rem;
  height: 0.01rem;
  background: #333333;
}
.header {
  font-size: 0.36rem;
  font-weight: 400;
  color: #333333;
  text-align: center;
  padding: 0.3rem 0;
}
.text {
  font-size: 0.28rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.5rem;
  padding: 0.3rem;
}
.texts {
  text-align: center;
}
.imgss-3 {
  padding: 0.3rem;
}
</style>
