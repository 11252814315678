<template>
  <div class="box">
    <div>
      <div class="tit">核心技术优势</div>
      <div class="text">
        为客户相关智慧城市项目提供本地化产品研发定制、工程实施、运营维护等服务，协助填补人才与技术不足，应对各种风险，交付卓越成果，并紧跟变革步伐。
      </div>
      <div class="one">
        <div><img class="img" src="@/assets/images/ai@2x.png" alt="" /></div>
        <div class="fu-tit">人工智能</div>
        <div class="neirong">
          <span>跨媒体AI</span><span style="padding: 0 0.3rem">鲁棒AI</span
          ><span>深度学习</span>
        </div>
      </div>
      <div class="one">
        <div><img class="img" src="@/assets/images/ai2.png" alt="" /></div>
        <div class="fu-tit">大数据</div>
        <div class="neirong">
          <span>大媒体检索</span
          ><span style="padding: 0 0.3rem">大数据分析</span
          ><span>大数据挖掘</span>
        </div>
      </div>
      <div class="one">
        <div><img class="img" src="@/assets/images/ai3.png" alt="" /></div>
        <div class="fu-tit">计算机视觉</div>
        <div class="neirong">
          <span>多模态识别</span><span style="padding: 0 0.3rem">行为分析</span
          ><span>内容理解</span>
        </div>
      </div>
      <div class="one">
        <div><img class="img" src="@/assets/images/ai4.png" alt="" /></div>
        <div class="fu-tit">VR/AR</div>
        <div class="neirong">
          <span>人机交互</span><span style="padding: 0 0.3rem">3D建模技术</span
          ><span> 虚拟人技术</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  padding: 1.2rem 0.3rem;
  text-align: center;
  background-color: #f4f4f4;
}
.one {
  margin-bottom: 0.66rem;
}
.tit {
  font-size: 0.5rem;
  font-weight: 400;
  color: #333333;
  padding: 0.37rem 0;
}
.text {
  font-size: 0.28rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.5rem;
}
.img {
  width: 1.22rem;
  height: 1.18rem;
  margin: auto;
  padding: 0.4rem;
}
.fu-tit {
  font-size: 0.4rem;
  font-weight: 400;
  color: #333333;
  padding: 0.3rem 0;
}
.neirong {
  font-size: 0.3rem;
  font-weight: 400;
  color: #999999;
  position: relative;
}
.neirong::after {
  content: '';
  display: block;
  width: 1.15rem;
  height: 0.08rem;
  background: #d2d2d2;
  position: absolute;
  left: 50%;
  bottom: -100%;
  transform: translateX(-50%);
}
</style>
