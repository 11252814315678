<template>
  <div class="box">
    <div class="tit">自主研发-软件产品</div>
    <div class="fu-tit">
      中天云网已研发N个应用及数据中台，集成多个信息化系统，并可根据需求定制。
    </div>
    <div class="list-box">
      <div>中天云网大数据AI中台</div>
      <div>中天云网城市大脑综合管理平台</div>
      <div>中天云网电子政务信息综合管理平台</div>
      <div>中天云网智慧乡村管理平台</div>
      <div>中天云网智慧社区管理平台</div>
      <div>中天云网智慧旅游综合管理服务平台</div>
      <div>中天云网垃圾分类管理系统</div>
      <div>中天云网智慧交通管理平台</div>
      <div>中天云网智慧医疗管理系统</div>
      <div>......</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  padding: 1rem 0.32rem;
  text-align: center;
  background: #f4f4f4;
}
.tit {
  font-size: 0.5rem;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0.4rem;
}
.fu-tit {
  font-size: 0.28rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.5rem;
  padding-bottom: 0.4rem;
}
.list-box > div {
  height: 1rem;
  line-height: 1rem;
  border: 0.01rem solid #666666;
  text-align: center;
  font-size: 0.3rem;
  font-weight: 400;
  color: #333333;
  margin: 0.3rem auto 0;
}
</style>
