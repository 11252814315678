<template>
  <div id="navbar">
    <!-- 导航栏 -->
    <!-- <Navigation /> -->
    <!-- 内容区域 -->
    <Content />
    <!-- <Core /> -->
    <!-- <Core2 /> -->
    <!-- <Core3 /> -->
    <!--底部-->
    <Foot />
  </div>
</template>

<script>
// import Navigation from '@/components/Navigation.vue'
import Foot from '@/components/Foot.vue'
import Content from './components/Content.vue'
import Content2 from './components/Content2.vue'
import Core from './components/Core.vue'
import Core2 from './components/Core2.vue'
import Core3 from './components/Core3.vue'
export default {
  name: 'Product',
  components: {
    // Navigation,
    Foot,
    Content
    // Content2,
    // Core,
    // Core2,
    // Core3
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.back-color {
  background-color: #f4f4f4;
  padding-bottom: 1.16rem;
}
.marg {
  margin-bottom: 0.43rem;
}
</style>
