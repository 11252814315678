<template>
  <div class="box">
    <div class="header">AI赋能</div>
    <div class="fu-head">
      中天云网新型AI智慧城市支撑城市全场景的智能化决策和自主演进
    </div>
    <div class="card">
      <div class="tit">AI生产力公共服务平台</div>
      <div class="text">
        为地方政府提供产业数字化转型的核心AI技术，打造地方产业数字化转型的AI生产力公共服务平台，赋能企业数字化转型的AI技术和服务。
      </div>
    </div>
    <div class="card">
      <div class="tit">AI行业大脑</div>
      <div class="text">
        利用平台服务能力以AI技术为核心，集成大数据、工业传感、5G等技术，构建垂直行业的AI行业大脑，在地方打造特色产业数字化转型的行业标杆。
      </div>
    </div>
    <div class="card">
      <div class="tit">AI行业应用</div>
      <div class="text">
        深入理解行业场景，基于人工智能操作系统，结合行业知识图谱快速输出行业算法模型和应用，为产业数字化转型各行业AI赋能。
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  padding: 1rem 0.32rem;
  text-align: center;
}
.header {
  font-size: 0.5rem;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0.39rem;
}
.fu-head {
  font-size: 0.28rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.5rem;
}
.card {
  height: 3.59rem;
  background: #ffffff;
  box-shadow: 0.03rem 0.03rem 0.21rem 0rem rgba(0, 0, 0, 0.18);
  margin: auto;
  margin-top: 0.65rem;
  border-top: 0.15rem solid #3979ff;
}
.tit {
  font-size: 0.4rem;
  font-weight: bold;
  color: #333333;
  padding-top: 0.7rem;
}
.text {
  font-size: 0.3rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.5rem;
  padding: 0.4rem 0.2rem 0;
}
</style>
